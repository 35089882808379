import React, { useState, useEffect } from 'react';
import { Alert, Col } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { VitagTitleBar } from '../general/VitagTitleBar';
import { VitagFooter } from '../general/VitagFooter';
import { doLogin } from '../services/AuthorizarionService';
import { GoogleAd } from '../utils/ads/GoogleAd';
import { loadUserPreferences } from '../utils/preferences/user-preferences';
import { useCustomToast } from '../utils/toasts/useCustomToast';
import { config } from '../config';
import Cookies from 'js-cookie';
import { ConfirmModal } from '../general/ConfirmModal';

function Login() {

    const history = useHistory();
    const { t } = useTranslation();
    const [credentials, setCredentials] = useState( { userEmail:'', userPass:'', showPass:false } );
    const [nextPage, setNextPage] = useState();
    const { addToast } = useCustomToast();
    const [showInfoModal, setShowInfoModal] = useState(false);
    const [cookieData, setCookieData] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        let retryCount = 0;
        const maxRetries = 3;
        const retryInterval = 500;

        document.title = config.TITLE_BASE + t('title_login');

        const getCookies = () => {
            const next = Cookies.get('vitag-next');
            if(config.IS_BETA) console.log('Next to: ' + next);
            setNextPage(next);
            if (next) {
                setShowInfoModal(true);    
                setLoading(false);
            } else if (retryCount < maxRetries) {
                retryCount++;
                setTimeout(getCookies, retryInterval);
            } else {
                setLoading(false);    
            }
        };
        getCookies();
    }, []);

    const handleUserInputChange = (event) => {
        const value = event.target.value;
        const newCredential = { userEmail: value, userPass: credentials.userPass, showPass: credentials.showPass };
        setCredentials(newCredential);
    }

    const handlePassInputChange = (event) => {
        const value = event.target.value;
        const newCredential = { userEmail: credentials.userEmail, userPass: value, showPass: credentials.showPass };
        setCredentials(newCredential);
    }

    const handlePassEnter = (event) => {
        const leKey = event.which || event.charCode || event.keyCode;
        if (leKey === 13 || leKey === '13') {
            tryDoLogin();
            event.preventDefault();
        }
    }

    const changeShowPass = () => {
        const newState = { userEmail: credentials.userEmail, userPass: credentials.userPass, showPass: ! credentials.showPass };
        setCredentials(newState);
    }

    const tryDoLogin = () => {
        doLogin(credentials.userEmail, credentials.userPass)
            .then(data => {
                if(config.IS_BETA) console.log('Response from login', data);
                localStorage.setItem('nibelungo', data.authc);
                loadUserPreferences();
                Cookies.remove('vitag-next');
                history.replace(nextPage || '/');
            })
            .catch(error => {
                if(config.IS_BETA) console.log('Login error: ', error);
                addToast({
                    header: t('page_login_toast_title'),
                    body: t('page_login_toast_text'),
                    variant: 'error'
                });
            });
    }
    
    return(
        <div className="full-container bg-vitag px-4 px-md-5" style={{overflowY:'scroll'}}>
            <div className="row justify-content-center">
                <div className="d-md-none d-flex col-12" style={{marginTop:'1em'}}></div>
                <div className="d-none d-md-flex col-12" style={{marginTop:'6em'}}></div>
                <div className="col-12 col-sm-10 col-md-9 col-lg-6 col-xl-5 px-md-4 py-3 mt-3 mb-5 bg-white border extra-rounded"
                    style={{zIndex:12}}>
                    <VitagTitleBar titleAtBar={t('page_login_title')}/>
                    {/* nextPage && <Alert variant='warning' className='d-flex align-items-center gap-3'>
                    <span className="material-icons-round">warning</span>
                        Antes de recibir el video necesitas iniciar sesión
                    </Alert> */}
                    <Col sm={12} className="form-group">
                        <label htmlFor='userEmail'>
                            {t('page_login_label_user')}
                        </label>
                        <input id="userEmail" name="userEmail" type="email" 
                            value={credentials.userEmail} onChange={handleUserInputChange}
                            placeholder={t('page_login_placeholder_user')}
                            className="form-control" required/>
                    </Col>
                    <Col sm={12} className="form-group">
                        <label htmlFor="userPass">
                            {t('page_login_label_password')}
                        </label>
                        <div className="input-group">
                            <input id="userPass" name="userPass"
                                type={credentials.showPass ? 'text' : 'password'}
                                value={credentials.userPass} onChange={handlePassInputChange} onKeyPress={handlePassEnter}
                                placeholder={t('page_login_placeholder_password')}
                                className="form-control" required/>
                            <div className="input-group-append">
                                <span onClick={changeShowPass}
                                    title={t('page_login_btn_showpassword_titleattr')}
                                    className="input-group-text material-icons-round">visibility</span>
                            </div>
                        </div>
                    </Col>
                    <Col sm={12} className="mt-4 pb-3 text-center">
                        <button onClick={tryDoLogin} disabled={! credentials.userEmail || ! credentials.userPass}
                            className="btn btn-primary px-5 bg-vitag">{t('page_login_btn_signin')}</button>
                        <div className='w-100 my-4 border-bottom'></div>
                        <p className="text-center mb-1">
                            {t('page_login_message_extrahelptosignin')}
                        </p>
                        <div className="d-flex justify-content-between pt-3">
                            <Link to={{pathname:"/sign-up", state:{next:nextPage}}}
                                className="btn btn-sm btn-link vitag-text">
                                {t('page_login_btn_signup')}
                            </Link>
                            <Link to={{pathname:"/recovery", state:{next:nextPage}}}
                                className="btn btn-sm btn-link vitag-text">
                                {t('page_login_btn_forgotpassword')}
                            </Link>
                        </div>
                    </Col>
                </div>
            </div>

            <ConfirmModal
                customClasses=' mt-5'
                modalTitle={t('page_login_modal_disclaimertitle')}
                showOkButton={false}
                cancelText='general_close'
                modalSize='lg'
                showModal={showInfoModal}
                cancelIcon={'info'}
                cancelIconRound={false}
                cancelIconSize={'32px'}
                setShowModal={setShowInfoModal} 
            >
                <div className="d-flex flex-column px-5">
                    <p>{t('page_login_modal_disclaimerbody1')}</p>
                    <p>{t('page_login_modal_disclaimerbody2')}</p>
                    <p>{t('page_login_modal_disclaimerbody3')}</p>
                    <button onClick={() => { setShowInfoModal(false);}}
                                className="btn bg-vitag text-white align-self-end mb-3" type='button'>
                                {t('modal_confirm_ok')}
                    </button>    
                </div>
            </ConfirmModal>

            <VitagFooter/>

            { /*<GoogleAd/>*/ }

        </div>
    );

}

export { Login };