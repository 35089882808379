import { faInfoCircle, faSortAlphaDown, faSortAlphaUp, faSortAmountDown, faSortAmountUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment/moment';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Col, Form, Row, Toast } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { config } from '../config';
import { GeneralContext } from '../contexts/general-context';
import { ConfirmModal } from "../general/ConfirmModal";
import { EmptyPanel } from "../general/EmptyPanel";
import { IconPill } from '../general/IconPill';
import { LoadingPanel } from '../general/LoadingPanel';
import { PaginationControl } from '../general/PaginationControl';
import { SimpleContextSelector } from '../general/SimpleContextSelector';
import { UpgradeComponent } from '../general/UpgradeComponent';
import { VideoItem } from "../general/VideoItem";
import { ModalAvancedSearch } from '../modals/ModalAvancedSearch';
import { ModalCheckout } from '../modals/ModalCheckout';
import { ModalDeleteVideo } from '../modals/ModalDeleteVideo';
import { ModalEditVideo } from '../modals/ModalEditVideo';
import { ModalImportMarkersForVideo } from '../modals/ModalImportMarkersForVideo';
import { ModalImportVideos } from '../modals/ModalImportVideos';
import { ModalListsForVideos } from '../modals/ModalListsForVideos';
import { ModalShare } from '../modals/ModalShare';
import { ModalSharedList } from '../modals/ModalSharedList';
import { ModalShowEvents } from '../modals/ModalShowEvents';
import { getLoggedUser } from '../services/AuthorizarionService';
import { getParameterByName } from "../services/CoreService";
import { createSubscription } from "../services/VitagSubscriptionsService";
import { allowNewVideo, cloneVideo, deleteOrDismissVideos, deleteVideo, ignoreVideo, listVideos, restoreVideo, restoreVideos } from "../services/VitagVideosService";
import { ModalNewLiveSession } from '../uploader/ModalNewLiveSession';
import { ModalNewVideo } from '../uploader/ModalNewVideo';
import { PERSONAL_CONTEXT } from '../utils/ContextUtils';
import { Parameters } from '../utils/ParametersEnum';
import { PREFERENCES, getPreferenceAsBool, getPreferenceAsNumber, savePreference } from '../utils/preferences/user-preferences';
import { useCustomToast } from '../utils/toasts/useCustomToast';
import { HomeBase } from "./HomeBase";

function VideosHome() {

    const SEARCH_KEYS = { TITLE:'qrazcv',TAGS:'89asqs',LISTS:'lois12',PAGE:'pktr64',SORT:'a87p9'};
    const [myVideos, setMyVideos] = useState([]);
    const [allSelectedVideoIds, setAllSelectedVideoIds] = useState([]);
    const [ownSelectedVideoIds, setOwnSelectedVideoIds] = useState([]);
    const [sharedSelectedVideoIds, setSharedSelectedVideoIds] = useState([]);
    const [totalCount, setTotalCount] = useState();
    const [currentPage, setCurrentPage] = useState(
        Number(localStorage.getItem(SEARCH_KEYS.PAGE) || '0')
    );
    const [pageSize, setPageSize] = useState(
        getPreferenceAsNumber(PREFERENCES.PAGE_SIZE)
    );
    const [loggedUser] = useState(getLoggedUser());
    const [showOnlyDeleted, setShowOnlyDeleted] = useState(false);
    const [currentVideo, setCurrentVideo] = useState();
    const [showErrorStopSharingModal, setShowErrorStopSharingModal] = useState(false);
    const [errorStopSharingDetail, setErrorStopSharingDetail] = useState('');
    const [showAvancedSearchModal, setShowAvancedSearchModal] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showManageListsModal, setShowManageListsModal] = useState(false);
    const [showShareModal, setShowShareModal] = useState(false);
    const [showSharedListModal, setShowSharedListModal] = useState(false);
    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
    const [showConfirmDeleteAllModal, setShowConfirmDeleteAllModal] = useState(false);
    const [showResumeLiveSession, setShowResumeLiveSession] = useState(false);
    const [startTimeForLiveSession, setStartTimeForLiveSession] = useState('0:00');
    const [showConfirmCloneModal, setShowConfirmCloneModal] = useState(false);
    const [showHelpModal, setShowHelpModal] = useState(false);
    const [showNewVideoModal, setShowNewVideoModal] = useState(false);
    const [newVideoUrl, setNewVideoUrl] = useState();
    const [showEditVideoModal, setShowEditVideoModal] = useState(false);
    const [showVideoEventsModal, setShowVideoEventsModal] = useState(false);
    const [showImportVideos, setShowImportVideos] = useState(false);
    const [showStartLiveSession, setShowStartLiveSession] = useState(false);
    const [showImportMarkersModal, setShowImportMarkersModal] = useState(false);
    const [currentTimeZone, setCurrentTimeZone] = useState('')
    const [loading, setLoading] = useState(true);
    const [cloneRequest, setCloneRequest] = useState({ newTitle:'', includeFavs:false, includeMarkers:false });
    const [cloneInOtherContext, setCloneInOtherContext] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [searchTags, setSearchTags] = useState([]);
    const [searchLists, setSearchLists] = useState([]);
    const [selectedCounter, setSelectedCounter] = useState(0);
    const [viewSelectOptions, setViewSelectOptions] = useState(false);
    const [sharedVideoIds, setSharedVideoIds] = useState([]);
    const [saveAsCopy, setSaveAsCopy] = useState(false);
    const [viewAsGrid, setViewAsGrid] = useState(
        getPreferenceAsBool(PREFERENCES.VIEW_AS_GRID)
    );
    const [successTitle, setSuccessTitle] = useState('Éxito');
    const [successMessage, setSuccessMessage] = useState('Todo ok');
    const lastTimeoutId = useRef(-1);
    const [ sortCriterias ] = useState([
        {value:'date-desc', name:'date', asc:false, icon:faSortAmountUp, label:'criteria_name_videos_date_desc'},
        {value:'name-asc', name:'name', asc:true, icon:faSortAlphaUp, label:'criteria_name_videos_name_asc'},
        {value:'date-asc', name:'date', asc:true, icon:faSortAmountDown, label:'criteria_name_videos_date_asc'},
        {value:'name-desc', name:'name', asc:false, icon:faSortAlphaDown, label:'criteria_name_videos_name_desc'},
    ]);
    const [selectedSortCriteria, setSelectedSortCriteria] = useState(sortCriterias[0]);
    const history = useHistory();
    const location = useLocation();
    const { addToast } = useCustomToast();
    const { t } = useTranslation();
    const { currentContext } = useContext(GeneralContext);
    const [showAlert, setShowAlert] = useState(true);
    const [showUpgradeModal, setShowUpgradeModal] = useState(false);
    const [showUpgradeUserModal, setShowUpgradeUserModal] = useState(false);
    const [allowFreeSub, setAllowFreeSub] = useState(false);
    const { isProUser } = useContext(GeneralContext);
    const [ isPersonalContext, setIsPersonalContext ] = useState(false);
    const [ imgFreeSub, setImgFreeSub ] = useState("");
    const [ freePlanconstraints, setFreePlanconstraints ] = useState(false);
    const [showCheckoutModal, setShowCheckoutModal] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState();

    useEffect(() => {
        document.title = config.TITLE_BASE + t('title_videos_home');
        getParameterByName(Parameters.ALLOW_FREE_SUBSCRIPTION)
            .then(resp => { 
                setAllowFreeSub(resp.data.value1 === "1");
            } );
        getParameterByName(Parameters.IMAGE_FREE_SUBSCRIPTION)
            .then(resp => { 
                setImgFreeSub(resp.data.value1);
            } );
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        setCurrentTimeZone(timezone);
        restoreLastSearch(location.state?.tag);
        enableFreeConstraints();
        const searchParams = new URLSearchParams(location.search);
        const newVideo = searchParams.get('new');
        if (newVideo) {
            setNewVideoUrl(newVideo);
            setShowNewVideoModal(true);
        }
    }, []);

    useEffect(() => {
        if (currentContext?.organizationId === PERSONAL_CONTEXT) {
            setIsPersonalContext(true);
        } else {
            setIsPersonalContext(false);
        }
        enableFreeConstraints();
    }, [currentContext])

    const enableFreeConstraints = () => {
        allowNewVideo().then(resp => {
            setFreePlanconstraints(true);
        })
        .catch(err => {
            setFreePlanconstraints(false);
        });
    }

    const getChecktoutInfo = (plan) => {
        setSelectedPlan(plan);
        setShowCheckoutModal(true);
    }

    const alertToastFreeConstraints = () => {
        if (!freePlanconstraints) {
            addToast({
                header: t('error_no_more_videos_title'),
                body: t('error_no_more_videos_body'),
                variant: 'error'
            });
        }
    }

    const avancedVideoSearch = (onlyDeleted, title = '', tags = [], lists = [], page = 0, sort) => {
        setShowOnlyDeleted(onlyDeleted);
        setSearchText(title);
        setSearchTags(tags);
        setSearchLists(lists);
        setCurrentPage(page);
        // setNeedUpdate(new Date());
        if (! sort) {
            sort = selectedSortCriteria;
        }
        saveCurrentSearch(title, tags, lists, page, sort.value);
        getVideos(
            title, tags.map(t => t.tagId), lists.map(list => list.value),
            page, pageSize, sort, onlyDeleted
        );
    }

    const performVideoSearch = () => {
        getVideos(
            searchText, searchTags.map(t => t.tagId), searchLists.map(list => list.value),
            currentPage, pageSize, selectedSortCriteria, showOnlyDeleted
        );
    }

    const performVideoSearchFromPagination = (page, pageSize) => {
        getVideos(
            searchText, searchTags.map(t => t.tagId), searchLists.map(list => list.value),
            page, pageSize, selectedSortCriteria, showOnlyDeleted
        );
        saveCurrentSearch(searchText, searchTags, searchLists, page, selectedSortCriteria.value);
    }

    const saveCurrentSearch = (title = '', tags = [], lists = [], page = 0, sort = 'date-desc') => {
        if(config.IS_BETA) console.log('Saving current search:', title, tags, lists, page);
        localStorage.setItem(SEARCH_KEYS.TITLE, title);
        localStorage.setItem(SEARCH_KEYS.TAGS, JSON.stringify(tags));
        localStorage.setItem(SEARCH_KEYS.LISTS, JSON.stringify(lists));
        localStorage.setItem(SEARCH_KEYS.PAGE, page);
        localStorage.setItem(SEARCH_KEYS.SORT, sort);
    }

    const restoreLastSearch = (newTagToSearch) => {
        const title = localStorage.getItem(SEARCH_KEYS.TITLE) || '';
        const tags = JSON.parse(localStorage.getItem(SEARCH_KEYS.TAGS) || '[]');
        const lists = JSON.parse(localStorage.getItem(SEARCH_KEYS.LISTS) || '[]');
        const page = Number(localStorage.getItem(SEARCH_KEYS.PAGE) || '0');
        const sort = localStorage.getItem(SEARCH_KEYS.SORT) || 'date-desc';
        const sortCriteria = sortCriterias.find(sc => sc.value === sort);
        setSelectedSortCriteria(sortCriteria);
        if(config.IS_BETA) console.log('Restore last search:', title, tags, lists, page, sort);
        if (newTagToSearch && ! tags.some(tag => tag.tagId == newTagToSearch.tagId)) {
            tags.push(newTagToSearch);
        }
        avancedVideoSearch(showOnlyDeleted, title, tags, lists, page, sortCriteria);
    }

    const removeSearchTag = (tagToRemove) => {
        const newSearchTags = [...searchTags].filter(tag => tag.tagId != tagToRemove.tagId);
        avancedVideoSearch(showOnlyDeleted, searchText, newSearchTags, searchLists);
    }

    const removeSearchList = (listToRemove) => {
        const newSearchLists = [...searchLists].filter(list => list.value != listToRemove.value);
        avancedVideoSearch(showOnlyDeleted, searchText, searchTags, newSearchLists);
    }

    const removeDeletedFilter = () => {
        avancedVideoSearch(false, searchText, searchTags, searchLists);
    }

    const getVideos = (
        searchValue = '', tagIds = [], listIds = [],
        page, pageSize, sortCriteria, onlyDeleted
    ) => {
        listVideos(
            searchValue, tagIds, listIds, page, pageSize,
            sortCriteria.name, sortCriteria.asc, onlyDeleted
        ).then(resp => {
            const allVideos = resp.data;
            const total = resp.headers['x-count-total'];
            setTotalCount(total);
            allVideos.forEach(v => {
                v.selected = false;
            });
            setSelectedCounter(0);
            setMyVideos(allVideos);
            setTimeout(setLoading, 600, false);
        })
        .catch(err => {
            if(config.IS_BETA) console.error('Error getting videos', err);
            setLoading(false);
        });
        enableFreeConstraints();
    }

    const handleSearch = (evt) => {
        const newSearch = evt.target.value;
        setSearchText(newSearch);
        if (lastTimeoutId.current !== -1) {
            clearTimeout(lastTimeoutId.current);
        }
        lastTimeoutId.current = setTimeout(() => {
            avancedVideoSearch(showOnlyDeleted, newSearch, searchTags, searchLists);
        }, 800);
    }

    const showHelpMessageForVideo = (video) => {
        setCurrentVideo(video);
        setShowHelpModal(true);
    }

    const acceptHelpMessageForVideo = () => {
        localStorage.setItem(currentVideo.videoId, 'true');
        window.open(currentVideo.url, "_blank").focus();
        setShowHelpModal(false);
    }

    const acceptUpgrade = () => {
        createSubscription(true,'DEFAULT', currentContext?.organizationId)
            .then(r => { 
                setShowUpgradeModal(false);
                addToast({
                    header: t('page_videos_toastsuccesssubscription_title'),
                    body: t('page_videos_toastsuccesssubscription_body'),
                    variant: 'success'
                });
                setAllowFreeSub(false);
                setTimeout(() => window.location.reload(), 3000);
            })
            .catch(err => {
                console.error("Error creating new subscription for current user", err);
            });
    }

    const confirmResumeLiveSession = (video) => {
        setCurrentVideo(video);
        setStartTimeForLiveSession('');
        setShowResumeLiveSession(true);
    }

    const confirmDeleteVideo = (video) => {
        setCurrentVideo(video);
        setShowConfirmDeleteModal(true);
    }

    const startListsManagement = (video) => {
        setCurrentVideo(video);
        setAllSelectedVideoIds([]);
        setShowManageListsModal(true);
    }

    const startShareVideo = (video) => {
        setSharedVideoIds(undefined);
        setCurrentVideo(video);
        setShowShareModal(true);
    }

    const startSharedList = (video) => {
        setSharedVideoIds(undefined);
        setCurrentVideo(video);
        setShowSharedListModal(true);
    }

    const startEditVideo = (video) => {
        setCurrentVideo(video);
        setShowEditVideoModal(true);
    }

    const startMarkerImportForVideo = (video) => {
        setCurrentVideo(video);
        setShowImportMarkersModal(true);
    }

    const confirmCloneVideo = (video, saveCopy = false) => {
        if (saveAsCopy) {
            setSuccessTitle(t('page_videos_modalsuccesscopyready_title'));
            setSuccessMessage(t('page_videos_modalsuccesscopyready_text'));
        } else {
            setSuccessTitle(t('page_videos_modalsuccessvideocloned_title'));
            setSuccessMessage(t('page_videos_modalsuccessvideocloned_text'));
        }
        setSaveAsCopy(saveCopy);
        setCurrentVideo(video);
        setCloneRequest({newTitle:`${video.title} ${t('page_videos_newtitlecopy')}`, includeFavs:false, includeMarkers:false});
        setCloneInOtherContext(false);
        setShowConfirmCloneModal(true);
    }

    const openVideoEventsModal = (video) => {
        setCurrentVideo(video);
        setShowVideoEventsModal(true);
    }


    const deleteCurrentVideo = (sharedDelete) => {
        const deletingVideo = {...currentVideo};
        deleteVideo(deletingVideo.hashId, sharedDelete)
            .then(resp => {
                if(config.IS_BETA) console.log('Deleted: ', resp);
                performVideoSearch();
            })
            .catch(err => {
                if(config.IS_BETA) console.error('Error deleting video...', err)
            });
    }

    const deleteOrDismissSelectedVideos = (sharedDelete) => {
        deleteOrDismissVideos(ownSelectedVideoIds, sharedSelectedVideoIds, sharedDelete)
            .then(_ => {
                if(config.IS_BETA) console.log('Deleted or dismissed success');
                setSuccessTitle(t('page_videos_modalsuccessdeleteall_title'));
                setSuccessMessage(t('page_videos_modalsuccessdeleteall_text'));
                setShowSuccessModal(true);
                setViewSelectOptions(false);
            })
            .catch(err => {
                if(config.IS_BETA) console.error('Error deleting/dismissing videos...', err);
                addToast({
                    header: t('page_videos_toasterrordeleteall_title'),
                    body: t('page_videos_toasterrordeleteall_text'),
                    variant: 'error'
                });
            });
    }

    const dismissVideo = (video) => {
        ignoreVideo(video.hashId)
            .then(_ => {
                if(config.IS_BETA) console.log('Stop sharing successful');
                performVideoSearch();
            })
            .catch(_ => {
                if(config.IS_BETA) console.error('Impossible stop sharing');
            });
    }

    const cloneCurrentVideo = () => {
        cloneVideo(currentVideo.hashId, cloneRequest)
            .then(resp => {
                if(config.IS_BETA) console.log('Video cloned...', resp);
                performVideoSearch();
                setShowSuccessModal(true);
            })
            .catch(err => {
                if(config.IS_BETA) console.error('Error cloning video', err);

                if(err.response.data == 'No space for more videos on free account'){
                    if (isPersonalContext) {
                        alertToastFreeConstraints();
                    } else {
                        addToast({
                            header: t('error_no_more_videos_title'),
                            body: t('error_no_more_videoscontext_body'),
                            variant: 'error'
                        });
                    }
                }
            });
    }

    const goToUploader = () => {
        allowNewVideo().then(resp => {
            setNewVideoUrl(undefined);
            setShowNewVideoModal(true);  
        })
        .catch(err => {
            if(config.IS_BETA) console.error('Video limit reached', err);
            setShowUpgradeUserModal(true);
        });
    }

    const goToStartLiveSession = () => {
        setShowStartLiveSession(true);
    }

    const checkedHandler = (videoId, check) => {
        let counter = 0;
        const sharedSelected = [];
        const ownSelected = [];
        const newVideos = myVideos.map(v => {
            if (v.videoId === videoId) {
                v.selected = check;
            }
            if (v.selected) {
                if (v.userId == loggedUser.userId) {
                    ownSelected.push(v.videoId);
                } else {
                    sharedSelected.push(v.videoId);
                }
                counter++;
            }
            return v;
        });
        setSelectedCounter(counter);
        setMyVideos(newVideos);
        setOwnSelectedVideoIds(ownSelected);
        setSharedSelectedVideoIds(sharedSelected);
        setAllSelectedVideoIds([...ownSelected, ...sharedSelected]);
    };

    const openListSelectorForVideos = () => {
        setCurrentVideo(undefined);
        setShowManageListsModal(true);
    }

    const changeAllVideos = (check) => {
        const newAllSelectedVideoIds = [];
        const newOwnSelectedVideoIds = [];
        const newSharedSelectedVideoIds = [];
        const newMyVideos = myVideos.map(
            v => {
                v.selected = check;
                if (! check) {
                    return v;
                }
                newAllSelectedVideoIds.push(v.videoId);
                if (v.userId === loggedUser.userId) {
                    newOwnSelectedVideoIds.push(v.videoId);
                } else {
                    newSharedSelectedVideoIds.push(v.videoId);
                }
                return v;
            });
        setAllSelectedVideoIds(newAllSelectedVideoIds);
        setOwnSelectedVideoIds(newOwnSelectedVideoIds);
        setSharedSelectedVideoIds(newSharedSelectedVideoIds);
        setMyVideos(newMyVideos);
        setSelectedCounter(newMyVideos.length);
    }

    const selectAllVideos = () => {
        if(config.IS_BETA) console.log('Selecting all videos...');
        changeAllVideos(true);
    }

    const unselectAllVideos = () => {
        if(config.IS_BETA) console.log('Unselecting all videos...');
        changeAllVideos(false);
    }

    const enableVideoSelection = () => {
        unselectAllVideos();
        setViewSelectOptions(true);
    }

    const cancelVideoSelection = () => {
        unselectAllVideos();
        setViewSelectOptions(false);
    }

    const successShareListener = () => {
        cancelVideoSelection();
        setCurrentVideo(undefined);
        setSuccessTitle(t('page_videos_modalsuccesssharevideos_title'));
        setSuccessMessage(t('page_videos_modalsuccesssharevideos_text'));
        setShowSuccessModal(true);
        performVideoSearch();
    }

    const startVideoImport = () => {
        setShowImportVideos(true);
    }

    const shareSelectedVideos = () => {
        if (ownSelectedVideoIds.length === 1) {
            const selectedVideoId = ownSelectedVideoIds[0];
            const videoToShare = myVideos.find(v => v.videoId == selectedVideoId);
            startShareVideo(videoToShare);
            return;
        }
        setCurrentVideo(undefined)
        setSharedVideoIds(ownSelectedVideoIds);
        setShowShareModal(true);
    }

    const selectContextHandler = evt => {
        setSuccessTitle(t('page_videos_modalsuccessclonedcontext_title'));
        setSuccessMessage(`${t('page_videos_modalsuccessclonedcontext_text')} '${evt?.name}'`);
        let newTitle = currentVideo?.title;
        if (evt?.organizationId == currentContext?.organizationId) {
            newTitle += " " + t('page_videos_newtitlecopy');
        }
        setCloneRequest({...cloneRequest, newTitle, newOwnerId:evt?.organizationId});
    }

    const enableContextHandler = evt => {
        if (! evt.target.checked) {
            setSuccessTitle(t('page_videos_modalsuccessclone_title'));
            setSuccessMessage(t('page_videos_modalsuccessclone_text'));
            setCloneRequest({...cloneRequest, newOwnerId: undefined});
        }
        setCloneInOtherContext(evt.target.checked);
    }

    const openAvancedSearchModal = () => {
        setShowAvancedSearchModal(true);
    }

    const openUpgradeModal = () => {
        setShowUpgradeModal(true);
    }

    const openLiveSession = () => {
        const shortDuration = /^[0-9]{1,2}:[0-9]{1,2}$/;
        let startTime = startTimeForLiveSession.trim();
        if (shortDuration.exec(startTime)) {
            startTime = '00:' + startTime;
        }
        const startTimeDuration = moment.duration(startTime);
        if(! startTimeDuration.isValid()) {
            history.push(`live/${currentVideo.hashId}`);
            return;
        }
        const secondsToStart = startTimeDuration.asSeconds();
        if(config.IS_BETA) console.log('Starting at: ' + secondsToStart);
        history.push({
            pathname:`live/${currentVideo.hashId}`,
            state:{start:secondsToStart}
        });
    }

    const resetSearch = () => {
        avancedVideoSearch(false, '', [], []);
    }

    const triggerGridListView = () => {
        savePreference(PREFERENCES.VIEW_AS_GRID, ! viewAsGrid);
        setViewAsGrid(! viewAsGrid);
    }

    const changeSortCriteria = (evt) => {
        setSelectedSortCriteria(evt);
        avancedVideoSearch(showOnlyDeleted, searchText, searchTags, searchLists, 0, evt);
    }

    const restoreThisVideo = (video) => {
        restoreVideo(video.hashId)
            .then(_ => {
                if(config.IS_BETA) console.log('Restored...');
                performVideoSearch();
            })
            .catch(err => {
                if(config.IS_BETA) console.error('Error restoring video', err);
                alertToastFreeConstraints();
            });
    }
        
    const restoreSelectedVideos = () => {
        const hashes = myVideos
            .filter(v => v.selected)
            .map(v => v.hashId);
        restoreVideos(hashes)
            .then(_ => {
                if(config.IS_BETA) console.log('All videos restored...');
                performVideoSearch();
                cancelVideoSelection();
            })
            .catch(err => {
                if(config.IS_BETA) console.error('Error restoring videos', err);
                alertToastFreeConstraints();
            });
    }

    const optionsForVideo = (video) => {
        if(showOnlyDeleted) {
            return [ { action:'restore-video', label:'Recuperar video', show: true } ];
        }
        return [
            { action: 'edit-video', show: loggedUser?.userId === video.userId, label: t('page_videos_videoitem_option_edit')},
            { action: 'share-video', show: loggedUser?.userId === video.userId, label: t('page_videos_videoitem_option_share')},
            { action: 'shared-list', show: loggedUser?.userId === video.userId, label: t('page_videos_videoitem_option_sharedlist')},
            { action: 'clone-video', show: true, label: t('page_videos_videoitem_option_clone')},
            { action: 'list-for-video', show: true, label: t('page_videos_videoitem_option_managelists')},
            { action: 'show-video-events', show: loggedUser?.userId === video.userId, label: t('page_videos_videoitem_option_show_events')},
            { action: 'delete-video', show: loggedUser?.userId === video.userId, label: t('page_videos_videoitem_option_delete')},
            { action: 'dismiss-video', show: loggedUser?.userId !== video.userId || video.isTrainingMaterial, label: t('page_videos_videoitem_option_dismiss')},
            { action: 'save-copy-video', show: loggedUser?.userId !== video.userId && video.isTrainingMaterial, label: t('page_videos_videoitem_option_savecopy')},
            { action: 'import-markers-for-video', show: false, label: t('page_videos_videoitem_option_importmarkers')}
        ];
    };

    const handleVideoOptionClicked = (video, option) => {
        if(config.IS_BETA) console.log('Option clicked...', option);
        switch(option.action) {
            case 'edit-video':
                return startEditVideo(video);
            case 'share-video':
                return startShareVideo(video);
            case 'shared-list':
                return startSharedList(video);
            case 'clone-video':
                return confirmCloneVideo(video);
            case 'list-for-video':
                return startListsManagement(video);
            case 'delete-video':
                return confirmDeleteVideo(video);
            case 'dismiss-video':
                return dismissVideo(video);
            case 'save-copy-video':
                return confirmCloneVideo(video, true);
            case 'import-markers-for-video':
                return startMarkerImportForVideo(video);
            case 'restore-video':
                return restoreThisVideo(video);
            case 'show-video-events':
                return openVideoEventsModal(video);
            default:
                break;
        }
    }

    return (<React.Fragment>
        <HomeBase
            showSearchBar={false}
            showExtraButtons={false}
            resetListener={resetSearch}
            currentTabName="videos">
            <div className='row mb-2'>
                <div className='col-12 col-sm-12 col-md-6 col-lg-5 d-flex flex-column'>
                    <div className="w-100 d-flex">
                        <input value={searchText} onChange={handleSearch} className="form-control my-auto mr-3" type="text"
                            id="searchInput" name="searchInput" placeholder={t('page_videos_placeholder_search')}/>
                        <button onClick={openAvancedSearchModal} className="btn btn-sm my-auto align-self-start" title={t('page_videos_button_advancedsearch_title')}>
                            <span className="material-symbols-outlined">manage_search</span>
                        </button>
                        <div className="dropdown my-auto" title={t(selectedSortCriteria.label)}>
                            <button className="btn mb-1 align-self-start dropdown-toggle" type="button" id="sortCrirteriaSelector" data-toggle="dropdown" aria-expanded="false">
                                <FontAwesomeIcon icon={selectedSortCriteria?.icon}/>
                            </button>
                            <ul className="dropdown-menu" aria-labelledby="sortCrirteriaSelector">
                                { sortCriterias.map(sc =>
                                <li key={sc.value} onClick={() => changeSortCriteria(sc)}
                                    className="dropdown-item cursor-pointer d-flex align-items-center gap-2">
                                    <FontAwesomeIcon icon={sc.icon}/>
                                    {t(sc.label)}
                                </li>
                                )}
                            </ul>
                        </div>
                    </div>
                    <div className="d-flex w-100 pt-2" style={{overflowX:'auto',gap:'6px'}}>
                        { searchTags.map(st =>
                            <IconPill key={st.tagId} onCloseClick={() => removeSearchTag(st)}
                                iconText={st.icon} iconColor={st.color} iconSize="sm" pillText={st.name} closable={true}/>) }
                        { searchLists.map(list =>
                            <IconPill key={list.value} onCloseClick={() => removeSearchList(list)}
                                iconText="list" iconColor="#0d0d0d" iconSize="sm" pillText={list.label} closable={true}/>) }
                        { showOnlyDeleted &&
                            <IconPill
                                onCloseClick={removeDeletedFilter}
                                iconColor="#ff0000" iconText="delete_sweep" iconSize="sm"
                                pillText={t('page_videos_filtertag_deleted')} closable={true}/> }
                    </div>
                </div>
                { ! viewSelectOptions &&
                <div className='col col-sm-12 col-md-6 col-lg-7 d-flex gap-3 justify-content-end align-items-center'>
                    <button onClick={enableVideoSelection} title={t('page_videos_button_selectvideos')} className=' btn btn-sm py-0 line-0 text-muted'>
                        <span className="material-icons-round">checklist</span>
                    </button>
                    <button onClick={triggerGridListView} title={t('general_view_as_grid_or_list_titleattr')} className="btn btn-sm py-0 line-0 text-muted">
                        <span className="material-icons-round">
                            { viewAsGrid ? 'view_list' : 'grid_view' }
                        </span>
                    </button>
                    <div className="btn-group">
                        <button onClick={goToUploader} className="btn btn-sm btn-primary bg-vitag">
                            {t('page_videos_button_newvideo')}
                        </button>
                        <button title={t('page_videos_button_newvideo_caret_title')} className="btn btn-sm btn-primary bg-vitag dropdown-toggle dropdown-toggle-split" data-toggle="dropdown" aria-expanded="false">
                            <span className="sr-only">Toggle Dropdown</span>
                        </button>
                        <div className="dropdown-menu dropdown-menu-right">
                            <button onClick={goToUploader} className="dropdown-item" type='button'>
                                {t('page_videos_newvideo_option_uploadvideo')}
                            </button>
                            <button onClick={goToStartLiveSession} className="dropdown-item" type='button'>
                                {t('page_videos_newvideo_option_startlivesession')}
                            </button>
                            <div className="d-flex">
                                <button onClick={startVideoImport} disabled={!freePlanconstraints} className="dropdown-item" type='button'>
                                    {t('page_videos_newvideo_option_uploadvideoscsv')}
                                </button>
                                {!freePlanconstraints && <button onClick={() => setShowUpgradeUserModal(true)} className="dropdown-item info" title={t('error_no_more_videos_body')} type='button'>
                                    <FontAwesomeIcon className="cursor-pointer" icon={faInfoCircle} style={{fontSize:'1.1em'}}/>
                                </button>}
                            </div>
                        </div>
                    </div>
                </div> }
                { viewSelectOptions && <div className='col-12 col-sm-12 col-md-6 col-lg-7 d-flex gap-4 justify-content-end align-items-center'>
                    <button onClick={cancelVideoSelection} title={t('page_videos_button_cancel')} className='btn btn-sm p-0 line-0 btn-outline-danger border-0'>
                        <span className="material-icons-round">close</span>
                    </button>
                    <button onClick={selectAllVideos} title={t('page_videos_button_selectall')} className='btn btn-sm text-muted p-0 line-0'>
                        <span className="material-icons-round">check_box</span>
                    </button>
                    <button onClick={unselectAllVideos} title={t('page_videos_button_cleanselection')} className='btn btn-sm text-muted p-0 line-0'>
                        <span className="material-icons-round">check_box_outline_blank</span>
                    </button>
                    { selectedCounter > 0 &&
                    <div className="dropdown">
                        <div className="btn btn-sm btn-primary bg-vitag" title={t('page_videos_button_videoactions')} data-toggle="dropdown">
                            {t('page_videos_button_videoactions')}
                        </div>
                        <div className="dropdown-menu dropdown-menu-right">
                            { ! showOnlyDeleted && <>
                            <button onClick={() => setShowConfirmDeleteAllModal(true)} className="dropdown-item">
                                { t('page_videos_option_deleteordismissvideos') }
                            </button>
                            <button onClick={openListSelectorForVideos} className="dropdown-item">
                                { t('page_videos_option_managevideoslists') }
                            </button>
                            { ownSelectedVideoIds.length > 0 &&
                            <button onClick={shareSelectedVideos} className="dropdown-item">
                                { t('page_videos_option_sharevideos') }
                            </button> }
                            </> }
                            { showOnlyDeleted && ownSelectedVideoIds.length > 0 &&
                            <button onClick={restoreSelectedVideos} className="dropdown-item">
                                { t('page_videos_option_restoresvideos') }
                            </button> }
                        </div>
                    </div> }
                </div> }
            </div>

            { loading &&
                <LoadingPanel/>
            }

            { ! loading && myVideos.length === 0 &&
                <EmptyPanel message={t('page_videos_message_novideos')}/>
            }

            { ! loading && myVideos.length > 0 &&
                <Row className='mt-3 home-container'>
                    { myVideos.map(video => 
                    <VideoItem
                        key={video.videoId}
                        video={video}
                        forRecyclerBin={showOnlyDeleted}
                        disableItem={showOnlyDeleted}
                        showFavoriteIndicator={! showOnlyDeleted}
                        loggedUser={loggedUser}
                        timezone={currentTimeZone}
                        showSelectionIndicator={viewSelectOptions}
                        showAsGrid={viewAsGrid}
                        notifyVideoSelection={checkedHandler}
                        customOptions={optionsForVideo(video)}
                        handleOptionClick={handleVideoOptionClicked}
                        resumeLiveSession={() => confirmResumeLiveSession(video)}/>
                    )}
                </Row>
            }

            { totalCount > 0 && 
                <PaginationControl
                    totalCount={totalCount}
                    pageSize={pageSize} setPageSize={setPageSize}
                    currentPage={currentPage} setCurrentPage={setCurrentPage}
                    paginationChangeListener={performVideoSearchFromPagination}/>
            }

        </HomeBase>

        <ModalAvancedSearch
            currentSearchTags={searchTags}
            currentLists={searchLists}
            currentOnlyDeleted={showOnlyDeleted}
            avancedSearchListener={avancedVideoSearch}
            showModal={showAvancedSearchModal}
            setShowModal={setShowAvancedSearchModal}/>

        <ModalDeleteVideo
            modalTitle={t('modal_videos_deletevideo_title')}
            modalText={t('modal_videos_deletevideo_text', {videotitle:currentVideo?.title})}
            acceptCallback={deleteCurrentVideo}
            showModal={showConfirmDeleteModal}
            setShowModal={setShowConfirmDeleteModal}
            vtgVideo={currentVideo}
            isMultiple={false}/>

        <ModalDeleteVideo
            modalTitle={t('modal_videos_deleteordismissvideos_title')}
            modalText={t('modal_videos_deleteordismissvideos_text')}
            acceptCallback={deleteOrDismissSelectedVideos}
            showModal={showConfirmDeleteAllModal}
            setShowModal={setShowConfirmDeleteAllModal}
            isMultiple={true}/>

        <ConfirmModal
            modalTitle={t('modal_resumelivesession_title')}
            modalText={t('modal_resumelivesession_text')}
            acceptCallback={openLiveSession}
            showModal={showResumeLiveSession}
            setShowModal={setShowResumeLiveSession}>
            <div className="d-flex pt-2">
                <div className="form-group d-flex w-100 align-items-center border-top pt-4">
                    <label className='w-50 text-right text-muted px-3 text-wrap m-0' htmlFor="starttime-for-live-session">
                        {t('modal_resumelivesession_label_starttime')}
                    </label>
                    <input value={startTimeForLiveSession}
                        onChange={evt => setStartTimeForLiveSession(evt.target.value)}
                        id='starttime-for-live-session' type="text" className="w-50 form-control"
                        placeholder={t('modal_resumelivesession_placeholder_starttime')}/>
                </div>
            </div>
        </ConfirmModal>

        <ConfirmModal
            modalTitle={saveAsCopy ? 'modal_videos_savecopy_title' : 'modal_videos_clonevideo_title'}
            translateModalTitle={true}
            modalText={saveAsCopy ? t('modal_videos_savecopy_text') : t('modal_videos_clonevideo_text', {videotitle:currentVideo?.title})}
            acceptCallback={cloneCurrentVideo}
            showModal={showConfirmCloneModal}
            okIcon='save_as' okText={t('general_save')}
            setShowModal={setShowConfirmCloneModal}>
            <Form.Group as={Row} controlId="cloneName" className='mb-4'>
                <Form.Label className="text-wrap" column sm={12}>
                    {t(saveAsCopy ? 'modal_videos_savecopy_label_copytitle' : 'modal_videos_clonevideo_label_newtitle')}
                </Form.Label>
                <Col sm={12}>
                    <Form.Control value={cloneRequest.newTitle} type="text"
                        placeholder={t('modal_videos_placeholder_newtitle', {videotitle:currentVideo?.title})}
                        disabled={saveAsCopy} onChange={(evt) => setCloneRequest({...cloneRequest, newTitle:evt.target.value})}/>
                </Col>
            </Form.Group>
            { ! saveAsCopy &&
            <Row className='mb-4'>
                <Col sm={12} className="mb-3">
                    <div className="form-check">
                        <input onChange={(evt) => setCloneRequest({...cloneRequest, includeMarkers:evt.target.checked})} value={cloneRequest.includeMarkers} className="form-check-input" type="checkbox" id="includeMarks"/>
                        <label className="form-check-label cursor-pointer mx-3" htmlFor="includeMarks">
                            {t('modal_videos_clonevideo_label_includemarkers')}
                        </label>
                    </div>
                </Col>
                <Col sm={12} className="mb-3">
                    <div className="form-check">
                        <input onChange={(evt) => setCloneRequest({...cloneRequest, includeFavs:evt.target.checked})} value={cloneRequest.includeFavs} className="form-check-input" type="checkbox" id="includeFavs"/>
                        <label className="form-check-label cursor-pointer mx-3" htmlFor="includeFavs">
                            {t('modal_videos_clonevideo_label_includefavs')}
                        </label>
                    </div>
                </Col>
                <Col sm={12} className="mb-3">
                    <div className="form-check">
                        <input onChange={enableContextHandler} value={cloneInOtherContext} className="form-check-input" type="checkbox" id="cloneOtherContext"/>
                        <label className="form-check-label cursor-pointer mx-3" htmlFor="cloneOtherContext">
                            {t('modal_videos_clonevideo_label_inothercontext')}
                        </label>
                    </div>
                </Col>
                { cloneInOtherContext &&
                <Col sm={12} className="mb-3">
                    <label className="form-label">
                        {t('modal_videos_clonevideo_label_selectnewcontext')}
                    </label>
                    <SimpleContextSelector changeContextListener={selectContextHandler}/>
                </Col> }
            </Row>}
        </ConfirmModal>

        <ModalEditVideo
            leVideo={currentVideo}
            saveVideoListener={() => performVideoSearch()}
            showModal={showEditVideoModal}
            setShowModal={setShowEditVideoModal}/>

        <ModalShowEvents
            vtgVideo={currentVideo}
            showModal={showVideoEventsModal}
            setShowModal={setShowVideoEventsModal} />

        <ModalShare
            vtgVideo={currentVideo}
            videoIds={sharedVideoIds}
            saveVideoListener={() => performVideoSearch()}
            successListener={successShareListener}
            showModal={showShareModal}
            setShowModal={setShowShareModal}/>

        <ModalSharedList
            vtgVideo={currentVideo}
            videoIds={sharedVideoIds}
            successListener={successShareListener}
            showModal={showSharedListModal}
            setShowModal={setShowSharedListModal}/>

        <ModalListsForVideos
            vtgVideo={currentVideo}
            selectedVideoIds={allSelectedVideoIds}
            showModal={showManageListsModal}
            setShowModal={setShowManageListsModal}/>

        <ConfirmModal
            modalTitle={successTitle}
            modalText={successMessage}
            showModal={showSuccessModal}
            setShowModal={setShowSuccessModal}
            acceptCallback={performVideoSearch}
            showCancelButton={false}/>

        <ConfirmModal
            modalTitle={t('modal_removeerror_video_title')}
            modalText={t('modal_removeerror_video_body', {group:errorStopSharingDetail})}
            showModal={showErrorStopSharingModal}
            setShowModal={setShowErrorStopSharingModal}
            showCancelButton={false}/>

        <ConfirmModal
            modalTitle={t('modal_updatepro_subscription_title')}
            modalText={t('modal_updatepro_subscription_body')}
            showModal={showUpgradeModal}
            setShowModal={setShowUpgradeModal}
            acceptCallback={acceptUpgrade}>
            <div className="d-flex flex-column align-items-center justify-content-center gap-3 px-4">
                <img src={imgFreeSub} alt=" " loading='lazy' height="320"/>
                <button onClick={acceptUpgrade} type="button" className="btn btn-primary bg-vitag my-4">
                    {t('modal_updatepro_subscription_button')}
                </button>
            </div>
        </ConfirmModal>

        <ConfirmModal
            modalTitle={t('modal_nomorevideosallowed_title')}
            modalText={t('modal_nomorevideosallowed_body')}
            showModal={showUpgradeUserModal}
            setShowModal={setShowUpgradeUserModal}
            showOkButton={false}>
            <UpgradeComponent
                showModal={showUpgradeUserModal}
                setShowModal={setShowUpgradeUserModal}
                showCheckout={getChecktoutInfo}/>
        </ConfirmModal>

        <ConfirmModal
            modalTitle={t('modal_helpmessage_video_title')}
            acceptCallback={acceptHelpMessageForVideo}
            showModal={showHelpModal}
            setShowModal={setShowHelpModal}
            showCancelButton={false}>
                <p className='mt-3'>
                    {t('modal_helpmessage_video_body1')}
                </p>
                <p className='small text-muted'>
                    {t('modal_helpmessage_video_body2')}
                </p>
        </ConfirmModal>

        <ModalNewVideo
            showModal={showNewVideoModal}
            setShowModal={setShowNewVideoModal}
            externUrl={newVideoUrl}/>

        <ModalNewLiveSession
            showModal={showStartLiveSession}
            setShowModal={setShowStartLiveSession}/>

        <ModalImportVideos
            showModal={showImportVideos}
            setShowModal={setShowImportVideos}
            successListener={resetSearch}/>
        
        {(!isProUser && allowFreeSub && isPersonalContext) && <Toast className={"toast-info position-fixed"}
               show={showAlert} onClose={() => setShowAlert(false)}
               style={{right:'16px',bottom:'16px',zIndex:1039}}>
            <Toast.Header className="d-flex justify-content-between">
                <span className="fw-bold">{t('toast_updatepro_subscription_title')}</span>
            </Toast.Header>
            <Toast.Body>
                <a className="alert-link" role="button" onClick={openUpgradeModal}>{t('toast_updatepro_subscription_body1')}</a>
                {' '} {t('toast_updatepro_subscription_body2')}
            </Toast.Body>
        </Toast>}

        <ModalImportMarkersForVideo
            vtgVideo={currentVideo}
            showModal={showImportMarkersModal}
            setShowModal={setShowImportMarkersModal}/>

        <ModalCheckout 
            showModal={showCheckoutModal}
            setShowModal={setShowCheckoutModal}
            plan={selectedPlan}
        />

        <iframe id="player" type="text/html" style={{position:'absolute',width:'1px',height:'1px'}}
            src={`https://www.youtube.com/embed/2ixVMVA9stI?controls=1&autohide=0&autoplay=0&rel=0&showinfo=0&enablejsapi=1&modestbranding=1`}
            frameBorder="0" crossOrigin="anonymous">
        </iframe>

    </React.Fragment>);
}

export { VideosHome };
